import React, { useState } from "react";
import { Icon, InlineIcon } from "@iconify/react";
import informationSquare from "@iconify/icons-carbon/information-square";
import "bootstrap/dist/css/bootstrap.min.css";
import { Tooltip } from "reactstrap";

export default function HomePage() {
  const [infoShown, setInfoShown] = useState(false);

  const showInfo = () => {
    setInfoShown(!infoShown);
  };

  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [tooltipTwoOpen, setTooltipTwoOpen] = useState(false);
  const [tooltipHeadingOpen, setTooltipHeadingOpen] = useState(false);
  const [
    tooltipTransformationalOpen,
    setTooltipTransformationalOpen,
  ] = useState(false);
  const [
    tooltipSurfaceStructureOpen,
    setTooltipSurfaceStructureOpen,
  ] = useState(false);
  const [tooltipFive, setTooltipFive] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);
  const toggleTwo = () => setTooltipTwoOpen(!tooltipTwoOpen);
  const toggleHeading = () => setTooltipHeadingOpen(!tooltipHeadingOpen);
  const toggleTransformational = () =>
    setTooltipTransformationalOpen(!tooltipTransformationalOpen);
  const toggleSurfaceStructure = () =>
    setTooltipSurfaceStructureOpen(!tooltipSurfaceStructureOpen);
  const toggleFive = () => setTooltipFive(!tooltipFive);
  return (
    <>
      <h1 className="main-header">
        Chomsky's 'Aspects of the Theory of Syntax': An Online Visual
        Representation of Key Concepts
        {/* <Icon
          icon={informationSquare}
          style={{ color: "blue", fontSize: "1.5rem" }}
          className="info-icon"
          id="TooltipHeading"
          aria-label="Show explanation of phrase structure grammar and context free grammar"
        />
        <Tooltip
          placement="bottom"
          isOpen={tooltipHeadingOpen}
          target="TooltipHeading"
          toggle={toggleHeading}
        >
          Phrase structure grammar when <span className="italic">x + y</span>{" "}
          <sup>1a</sup> are nonnull values
          <br />
          <br />
          Context free/simple grammar when <span className="italic">
            x + y
          </span>{" "}
          <sup>1a</sup> are null values
        </Tooltip> */}
      </h1>

      <section className="intro">
        <p className="margin-bottom margin-top">
          {" "}
          This concept chart functions as a basic representation of the ideas
          presented by Noam Chomsky in his work “Aspects of the Theory of
          Syntax.” It attempts to visually describe how the different components
          of generative grammar (in a universal sense) interact with each other
          in order to create a grammatically correct sentence. It does not
          consider the context surrounding the sentence but does acknowledge the
          possibility of context affecting the basic strings which form the deep
          structure of a sentence.{" "}
        </p>
        <p>
          {" "}
          Generative Grammar, as described by Chomsky, is composed of three
          parts: a syntactic component, a semantic component, and a phonetic
          component. These parts are all interrelated and work with each other
          to produce a grammatically correct sentence that is able to be
          interpreted. More detail is given in his book towards explaining the
          inner-workings of the syntactic component, which regulates the rules
          for the arrangement of words in a sentence. The syntactic component is
          subdivided into the base part and the transformational part. The base
          part generates strings characterized by phrase-markers. These
          phrase-markers denote the individual function of the parts of a
          sentence and how they interact together. They also help to generate
          the deep structure of a sentence. The deep structure is then processed
          by the transformational component to generate the surface structure of
          a sentence. Lastly, the two structures are translated by the other two
          components. The semantic component interprets the original deep
          structure while the phonetic component interprets the surface
          structure. Thus, a grammatically correct sentence is produced and
          interpreted.{" "}
        </p>
      </section>
      <h2 className="heading-two underline">
        Visual Representation of Presented Concepts:
      </h2>
      <h3 className="italic">
        Generative Grammar{" "}
        <Icon
          icon={informationSquare}
          style={{ color: "blue", fontSize: "1.5rem" }}
          className="info-icon"
          id="TooltipHeading"
          aria-label="Show explanation of phrase structure grammar and context free grammar"
        />
        <Tooltip
          placement="bottom"
          isOpen={tooltipHeadingOpen}
          target="TooltipHeading"
          toggle={toggleHeading}
        >
          Phrase structure grammar when <span className="italic">x + y</span>{" "}
          <sup>1a</sup> are nonnull values
          <br />
          <br />
          Context free/simple grammar when <span className="italic">
            x + y
          </span>{" "}
          <sup>1a</sup> are null values
        </Tooltip>
      </h3>
      <section className="chart chart-top">
        <p className="margin-right">BASE PART</p>
        <svg
          className="triple-arrow"
          width="35"
          height="39"
          viewBox="0 0 35 39"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M34.3536 4.35355C34.5488 4.15829 34.5488 3.84171 34.3536 3.64645L31.1716 0.464466C30.9763 0.269204 30.6597 0.269204 30.4645 0.464466C30.2692 0.659728 30.2692 0.976311 30.4645 1.17157L33.2929 4L30.4645 6.82843C30.2692 7.02369 30.2692 7.34027 30.4645 7.53553C30.6597 7.7308 30.9763 7.7308 31.1716 7.53553L34.3536 4.35355ZM0 4.5L34 4.5V3.5L0 3.5L0 4.5Z"
            fill="black"
          />
          <path
            d="M34.3536 19.3536C34.5488 19.1583 34.5488 18.8417 34.3536 18.6464L31.1716 15.4645C30.9763 15.2692 30.6597 15.2692 30.4645 15.4645C30.2692 15.6597 30.2692 15.9763 30.4645 16.1716L33.2929 19L30.4645 21.8284C30.2692 22.0237 30.2692 22.3403 30.4645 22.5355C30.6597 22.7308 30.9763 22.7308 31.1716 22.5355L34.3536 19.3536ZM0 19.5L34 19.5V18.5L0 18.5L0 19.5Z"
            fill="black"
          />
          <path
            d="M34.3536 35.3536C34.5488 35.1583 34.5488 34.8417 34.3536 34.6464L31.1716 31.4645C30.9763 31.2692 30.6597 31.2692 30.4645 31.4645C30.2692 31.6597 30.2692 31.9763 30.4645 32.1716L33.2929 35L30.4645 37.8284C30.2692 38.0237 30.2692 38.3403 30.4645 38.5355C30.6597 38.7308 30.9763 38.7308 31.1716 38.5355L34.3536 35.3536ZM0 35.5L34 35.5V34.5L0 34.5L0 35.5Z"
            fill="black"
          />
        </svg>
        <svg
          className="triple-arrow-desktop"
          width="153"
          height="39"
          viewBox="0 0 153 39"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M35.3536 4.35355C35.5488 4.15829 35.5488 3.84171 35.3536 3.64645L32.1716 0.464466C31.9763 0.269204 31.6597 0.269204 31.4645 0.464466C31.2692 0.659728 31.2692 0.976311 31.4645 1.17157L34.2929 4L31.4645 6.82843C31.2692 7.02369 31.2692 7.34027 31.4645 7.53553C31.6597 7.7308 31.9763 7.7308 32.1716 7.53553L35.3536 4.35355ZM1 4.5L35 4.5V3.5L1 3.5L1 4.5Z"
            fill="black"
          />
          <path
            d="M152.356 18.3445C152.55 18.148 152.548 17.8314 152.351 17.6374L149.148 14.4767C148.951 14.2828 148.635 14.2849 148.441 14.4814C148.247 14.678 148.249 14.9946 148.446 15.1885L151.293 17.998L148.483 20.8453C148.289 21.0418 148.292 21.3584 148.488 21.5524C148.685 21.7463 149.001 21.7442 149.195 21.5476L152.356 18.3445ZM1.00333 19.5L152.003 18.4933L151.997 17.4933L0.996667 18.5L1.00333 19.5Z"
            fill="black"
          />
          <path
            d="M35.3536 35.3536C35.5488 35.1583 35.5488 34.8417 35.3536 34.6464L32.1716 31.4645C31.9763 31.2692 31.6597 31.2692 31.4645 31.4645C31.2692 31.6597 31.2692 31.9763 31.4645 32.1716L34.2929 35L31.4645 37.8284C31.2692 38.0237 31.2692 38.3403 31.4645 38.5355C31.6597 38.7308 31.9763 38.7308 32.1716 38.5355L35.3536 35.3536ZM1 35.5L35 35.5V34.5L1 34.5L1 35.5Z"
            fill="black"
          />
        </svg>
        <div className="margin-left margin-right inline">
          <p className="inline">
            base string{" "}
            <sup>
              {" "}
              <span
                style={{ textDecoration: "underline", color: "blue" }}
                href="#"
                id="TooltipBaseString"
              >
                1
              </span>
            </sup>
            <Tooltip
              placement="top"
              isOpen={tooltipOpen}
              target="TooltipBaseString"
              toggle={toggle}
            >
              <sup>1</sup> subdivided by linear sequential sequence of
              "REWRITING RULES" <sup>1a</sup>
              <br />
              <br />
              <sup>1a</sup> A &rarr; Z / X &rarr; Y<br /> A = category
              <br /> Z = string of symbols
              <br /> X + Y = context
            </Tooltip>{" "}
          </p>
          <div className="inline">
            <p className="inline">
              {" "}
              &nbsp;w/ phrase-marker
              <sup>
                {" "}
                <span
                  style={{ textDecoration: "underline", color: "blue" }}
                  href="#"
                  id="TooltipPhraseMarker"
                >
                  2{" "}
                </span>
              </sup>
              <Tooltip
                placement="top"
                isOpen={tooltipTwoOpen}
                target="TooltipPhraseMarker"
                toggle={toggleTwo}
              >
                <sup>2</sup> structured description associated with each basic
                string, of which there is a highly restricted amount, producing
                in turn "a restricted set of base phrase-markers."
              </Tooltip>{" "}
            </p>
          </div>
        </div>
        <svg
          className="post-phrase-marker-arrow"
          width="35"
          height="8"
          viewBox="0 0 35 8"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M34.3536 4.35355C34.5488 4.15829 34.5488 3.84171 34.3536 3.64645L31.1716 0.464466C30.9763 0.269204 30.6597 0.269204 30.4645 0.464466C30.2692 0.659728 30.2692 0.976311 30.4645 1.17157L33.2929 4L30.4645 6.82843C30.2692 7.02369 30.2692 7.34027 30.4645 7.53553C30.6597 7.7308 30.9763 7.7308 31.1716 7.53553L34.3536 4.35355ZM0 4.5L34 4.5V3.5L0 3.5L0 4.5Z"
            fill="black"
          />
        </svg>

        <p className="margin-left">deep structure</p>
      </section>
      <section className="chart chart-mid-1">
        <svg
          className="diagonal-down"
          width="73"
          height="176"
          viewBox="0 0 73 176"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M68.6464 175.354C68.8417 175.549 69.1583 175.549 69.3536 175.354L72.5355 172.172C72.7308 171.976 72.7308 171.66 72.5355 171.464C72.3403 171.269 72.0237 171.269 71.8284 171.464L69 174.293L66.1716 171.464C65.9763 171.269 65.6597 171.269 65.4645 171.464C65.2692 171.66 65.2692 171.976 65.4645 172.172L68.6464 175.354ZM68.5 4L68.5 175H69.5L69.5 4H68.5Z"
            fill="black"
          />
          <path
            d="M1.55689 24.7684C1.42896 25.0131 1.52365 25.3152 1.76837 25.4431L5.75639 27.5278C6.00111 27.6557 6.3032 27.561 6.43113 27.3163C6.55905 27.0715 6.46437 26.7695 6.21964 26.6415L2.67474 24.7885L4.52776 21.2436C4.65568 20.9989 4.561 20.6968 4.31627 20.5689C4.07155 20.4409 3.76946 20.5356 3.64153 20.7804L1.55689 24.7684ZM68.8505 3.52289L1.85046 24.5229L2.14954 25.4771L69.1495 4.47711L68.8505 3.52289Z"
            fill="black"
          />
        </svg>
        <svg
          className="desktop-top-diagonal"
          width="336"
          height="305"
          viewBox="0 0 336 305"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2.54352 301.945C2.53588 302.221 2.75346 302.451 3.02949 302.459L7.52777 302.583C7.8038 302.591 8.03377 302.373 8.04142 302.097C8.04906 301.821 7.83149 301.591 7.55545 301.584L3.55698 301.473L3.66771 297.475C3.67535 297.199 3.45778 296.969 3.18174 296.961C2.9057 296.953 2.67573 297.171 2.66809 297.447L2.54352 301.945ZM318.688 2.6368L2.6997 301.596L3.38696 302.322L319.375 3.3632L318.688 2.6368Z"
            fill="black"
          />
          <path
            d="M331.62 133.749C331.833 133.925 332.149 133.893 332.324 133.68L335.177 130.2C335.352 129.986 335.321 129.671 335.107 129.496C334.893 129.321 334.578 129.352 334.403 129.566L331.867 132.659L328.774 130.123C328.56 129.948 328.245 129.979 328.07 130.193C327.895 130.406 327.926 130.721 328.14 130.896L331.62 133.749ZM318.534 3.04926L331.439 133.412L332.435 133.314L319.529 2.95074L318.534 3.04926Z"
            fill="black"
          />
        </svg>
      </section>
      <section className="chart chart-mid-2">
        <p className="transformational">
          TRANSFORMATIONAL PART{" "}
          <sup>
            {" "}
            <span
              style={{ textDecoration: "underline", color: "blue" }}
              href="#"
              id="TooltipTransformational"
            >
              3
            </span>
          </sup>
          <Tooltip
            placement="top"
            isOpen={tooltipTransformationalOpen}
            target="TooltipTransformational"
            toggle={toggleTransformational}
          >
            <sup>3</sup> utilization of the phrase-markers composing the deep
            structure in order to impose the surface structure of the sentence
          </Tooltip>
          <svg
            className="small-down-1"
            width="8"
            height="37"
            viewBox="0 0 8 37"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3.64645 36.3536C3.84171 36.5488 4.15829 36.5488 4.35355 36.3536L7.53553 33.1716C7.7308 32.9763 7.7308 32.6597 7.53553 32.4645C7.34027 32.2692 7.02369 32.2692 6.82843 32.4645L4 35.2929L1.17157 32.4645C0.976311 32.2692 0.659728 32.2692 0.464466 32.4645C0.269204 32.6597 0.269204 32.9763 0.464466 33.1716L3.64645 36.3536ZM3.5 0L3.5 36L4.5 36L4.5 0L3.5 0Z"
              fill="black"
            />
          </svg>
          surface structure
          <sup>
            {" "}
            <span
              style={{ textDecoration: "underline", color: "blue" }}
              href="#"
              id="TooltipSurfaceStructure"
            >
              4
            </span>
          </sup>
          <Tooltip
            placement="top"
            isOpen={tooltipSurfaceStructureOpen}
            target="TooltipSurfaceStructure"
            toggle={toggleSurfaceStructure}
          >
            <sup>4</sup> derived phrase-marker.
          </Tooltip>
          <svg
            className="small-down-1"
            width="8"
            height="37"
            viewBox="0 0 8 37"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3.64645 36.3536C3.84171 36.5488 4.15829 36.5488 4.35355 36.3536L7.53553 33.1716C7.7308 32.9763 7.7308 32.6597 7.53553 32.4645C7.34027 32.2692 7.02369 32.2692 6.82843 32.4645L4 35.2929L1.17157 32.4645C0.976311 32.2692 0.659728 32.2692 0.464466 32.4645C0.269204 32.6597 0.269204 32.9763 0.464466 33.1716L3.64645 36.3536ZM3.5 0L3.5 36L4.5 36L4.5 0L3.5 0Z"
              fill="black"
            />
          </svg>
        </p>
      </section>
      <section className="chart chart-bottom">
        <p className="phonetic">
          interpretation of surface structure (by phonetic component)
        </p>
        <p className="semantic">
          interpretation of deep structure (by semantic component)
        </p>
      </section>
      <section className="chart desktop-diagonal-bottom">
        <svg
          className="final-left"
          width="80"
          height="86"
          viewBox="0 0 65 52"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M64.7549 54.4116C65.0298 54.3853 65.2312 54.1411 65.2049 53.8662L64.7756 49.3867C64.7493 49.1118 64.5051 48.9103 64.2302 48.9367C63.9553 48.963 63.7538 49.2072 63.7802 49.4821L64.1618 53.4639L60.18 53.8455C59.9051 53.8718 59.7036 54.116 59.73 54.3909C59.7563 54.6658 60.0005 54.8673 60.2754 54.8409L64.7549 54.4116ZM2.68179 3.38567L64.389 54.2996L65.0254 53.5282L3.31821 2.61433L2.68179 3.38567Z"
            fill="black"
          />
        </svg>

        <svg
          className="final-right"
          width="80"
          height="86"
          viewBox="0 0 65 52"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2.5859 53.6093C2.55842 53.884 2.75889 54.1291 3.03366 54.1565L7.51133 54.6043C7.7861 54.6318 8.03113 54.4313 8.0586 54.1565C8.08608 53.8818 7.88561 53.6367 7.61084 53.6093L3.63069 53.2113L4.0287 49.2311C4.05618 48.9563 3.85571 48.7113 3.58093 48.6838C3.30616 48.6564 3.06114 48.8568 3.03366 49.1316L2.5859 53.6093ZM64.6834 2.61302L2.7668 53.272L3.40004 54.046L65.3166 3.38698L64.6834 2.61302Z"
            fill="black"
          />
        </svg>
      </section>
      <section className="chart desktop-bottom-interpretation">
        <p className="interpretation">interpretation of sentence &nbsp;</p>
        <sup>
          {" "}
          <span
            style={{ textDecoration: "underline", color: "blue" }}
            href="#"
            id="TooltipFive"
            className="interpretation"
          >
            5
          </span>
        </sup>
        <Tooltip
          placement="top"
          isOpen={tooltipFive}
          target="TooltipFive"
          toggle={toggleFive}
        >
          <sup>5</sup> sentence = a string of formatives.
          <sup>5a</sup> "a string of formatives specifies a string of phones
          uniquely (up to free variation), but not conversely (Chomsky, 16)."
        </Tooltip>
      </section>
      <section>
        <p className="italic">
          * All topics and subtopics are parts or processes of the syntactic
          component of generative grammar unless otherwise stated.
        </p>
      </section>
      <section>
        <p>
          This graph is a direct representation of the concepts presented by
          Chomsky. Included below are direct quotes, taken from “Aspects of the
          Theory of Syntax,” which explain each component of grammar and
          separated in regard to the component they describe.
        </p>
        <ul className="margin-top">
          <li>
            <span className="underline">
              Base Part (of Syntactic Component){" "}
            </span>
            - “A system of rules that generate a highly restricted (perhaps
            finite) set of basic strings.” (Chomsky, 17).
          </li>
          <li>
            <span className="underline">Base Strings </span> – “Each [has] an
            associated structural description called a base Phrase-marker”
            (Chomsky, 17); “The natural mechanism for generating
            Phrase-markers…is a system of rewriting rules. A rewriting rule is a
            rule of the form A &rarr; Z/X – Y.” (Chomsky, 66).
          </li>
          <li>
            <span className="underline">Deep Structure </span>– “…we define
            ‘deep structures’ as ‘structures generated by the base component…’”
            (Chomsky, 136); “A deep structure is a generalized Phrase-marker
            underlying some well-formed surface structure.” (Chomsky, 138).
            (Chomsky, 16).
          </li>
          <li>
            <span className="underline">Generative Grammar </span>– “…does not
            rely on the intelligence of the understanding reader but rather
            provides an explicit analysis of his contribution.” (Chomsky, 4); “A
            system of rules that in some explicit and well-defined way assigns
            structural descriptions to sentences.” (Chomsky, 8); “A generative
            grammar is not a modal for a speaker or a hearer. It attempts to
            characterize in the most neutral possible terms the knowledge of the
            language that provides the basis for actual use of language by a
            speaker-hearer.” (Chomsky, 9); “…the output of an acquisition model
            for language.” (Chomsky, 57); “…can be regarded only as a
            characterization of the intrinsic tacit knowledge or competence that
            underlies actual performance.” (Chomsky, 140).
          </li>
          <li>
            <span className="underline">Phonological Component </span>–
            “…determines the phonetic form of a sentence generated by the
            syntactic component. That is, it relates a structure generated by
            the syntactic component to a phonetically represented signal.”
            (Chomsky, 16).
          </li>
          <li>
            <span className="underline">Phrase Markers </span>– “The elementary
            units of which deep structures are constituted.” (Chomsky, 17);
            “Underlying each sentence of the language there is a sequence of
            base Phrase-markers, each generated by the base of the syntactic
            component.” (Chomsky, 17).
          </li>
          <li>
            <span className="underline">Semantic Component </span> –
            “…determines the semantic interpretation of a sentence. That is, it
            relates a structure generated by the syntactic component to a
            certain semantic representation.” (Chomsky, 16).
          </li>
          <li>
            <span className="underline">Surface Structure </span>– “…is
            interpreted…by the phonological component.” (Chomsky, 16).
          </li>
          <li>
            <span className="underline">Syntactic Component </span>– “The
            syntactic component specifies an infinite set of abstract formal
            objects, each of which incorporates all information relevant to a
            single interpretation of a particular sentence.” (Chomsky, 16);
            “…must specify, for each sentence, a deep structure that determines
            its semantic interpretation and a surface structure that determines
            its phonetic interpretation.” (Chomsky, 16); “…must generate deep
            and surface structures, for each sentence, and must interrelate
            them.” (Chomsky, 17); “… consists of a base that generates deep
            structures and a transformational part that maps them into surface
            structures.” (Chomsky, 135).
          </li>
          <li>
            <span className="underline">
              Transformational Part (of Syntactic Component){" "}
            </span>
            – “This is concerned with generating a sentence, with its surface
            structure, from its basis [which he defines as the sequence of base
            Phrase-markers which the base generates].” (Chomsky, 17); “The
            transformational component is solely interpretive.” (Chomsky, 137).
          </li>
        </ul>
      </section>
      <section>
        <p className="bold">Noteworthy Passages:</p>
        <ul>
          <li>
            “Real progress in linguistics consists in the discovery that certain
            features of given languages can be reduced to universal properties
            of language, and explained in terms of these deeper aspects of
            linguistic form.” (Chomsky, 35).
          </li>
          <li>
            “The basis of a sentence [is] the sequence of base Phrase-markers
            that underlies it. The basis of a sentence is mapped into the
            sentence by the transformational rules, which, furthermore,
            automatically assign to the sentence a derived Phrase-marker
            (ultimately, a surface structure) in the process.” (Chomsky, 128).
          </li>
          <li>
            “The final effect of a grammar, then, is to relate a semantic
            interpretation to a phonetic representation – that is, to state how
            a sentence is interpreted. This relation is mediated by the
            syntactic component of the grammar, which constitutes its sole
            “creative” part. (Chomsky, 136).
          </li>
          <li>
            “The transformational rules act as a ‘filter’ that permits only
            certain generalized Phrase-markers to qualify as deep structures.”
            (Chomsky, 139).
          </li>
        </ul>
      </section>
      <section>
        <p className="bold">Final Comments:</p>
        <p className="margin-top">
          The term “Generative Grammar” is defended by Chomsky in the first
          section of the first chapter of “Aspects of the Theory of Syntax.” He
          explains it to be an effective translation of the original German term
          of “erzeugen” which was coined by Humboldt.
        </p>
        <p className="margin-top">
          Students studying “Aspects of the Theory of Syntax” should read the
          full text in order to gain a complete picture of all the concepts
          discussed within. This website serves simply to help summarize main
          concepts for use in an academic setting or for one’s personal growth.
          It is by no means a complete representation of Chomsky’s work. A
          fuller background can only be obtained by reading the full work. More
          explanation is there afforded to assumptions behind the proposals,
          grammaticalness/acceptability, limitations of other grammars,
          examples, and the potential for formal and substantive linguistic
          universals. There is also a deep discussion of Phrase-markers
          (specifically in chapter 2, section 2), which are integral to
          understanding the concepts.{" "}
        </p>
        <p className="margin-top bold">Source:</p>
        <p className="margin-top">
          Chomsky, N. (1965). Aspects of the Theory of Syntax. Cambridge: The
          M.I.T. Press.
        </p>
      </section>
    </>
  );
}
